.bannerWrapper {
  width: 100%;
  height: var(--banner-height);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.bannerWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(var(--banner-brightness));
}
.bannerWrapper span {
  height: 100%;
  filter: brightness(var(--banner-brightness));
}
.centerContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.contentName {
  font-size: 50px;
  font-weight: 200;
  color: #d2d2d2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1;
}
