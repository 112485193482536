:root {
  --color-red: #e30100;
}

body {
  background-color: white;
}

/* ----------- wrapper ----------- */
.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper-margin {
  margin: 60px 0;
}
.wrapper-margin-top {
  margin-top: 60px;
}
.wrapper-margin-bottom {
  margin-bottom: 60px;
}

.wrapper-padding {
  padding: 60px 0;
}
.wrapper-padding-top {
  padding-top: 60px;
}
.wrapper-padding-bottom {
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .wrapper-margin {
    margin: 40px 0;
  }
  .wrapper-margin-top {
    margin-top: 40px;
  }
  .wrapper-margin-bottom {
    margin-bottom: 40px;
  }

  .wrapper-padding {
    padding: 40px 0;
  }
  .wrapper-padding-top {
    padding-top: 40px;
  }
  .wrapper-padding-bottom {
    padding-bottom: 40px;
  }
}

/* ---------- container ---------- */
.container {
  width: 90%;
  max-width: calc(1200px - 40px);
}

.container-margin {
  margin: 30px 0;
}
.container-margin-top {
  margin-top: 30px;
}
.container-margin-bottom {
  margin-bottom: 30px;
}

.container-padding {
  padding: 30px 0;
}
.container-padding-top {
  padding-top: 30px;
}
.container-padding-bottom {
  padding-bottom: 30px;
}

@media (max-width: 768px) {
  .container-margin {
    margin: 20px 0;
  }
  .container-margin-top {
    margin-top: 20px;
  }
  .container-margin-bottom {
    margin-bottom: 20px;
  }

  .container-padding {
    padding: 20px 0;
  }
  .container-padding-top {
    padding-top: 20px;
  }
  .container-padding-bottom {
    padding-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .container {
    width: calc(100% - 30px);
  }
}

/* ---------- font weight ---------- */
.weight-300 {
  font-weight: 300;
}
.weight-400 {
  font-weight: 400;
}
.weight-500 {
  font-weight: 500;
}
