/* ---------- text 16 ---------- */
.text-16 {
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  color: #282828;
}
@media (max-width: 380px) {
  .text-16 {
    font-size: 14px;
  }
}

/* ---------- text 18 ---------- */
.text-18 {
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  color: #282828;
}
@media (max-width: 1440px) {
  .text-18 {
    font-size: 17.5px;
  }
}
@media (max-width: 1200px) {
  .text-18 {
    font-size: 17px;
  }
}
@media (max-width: 768px) {
  .text-18 {
    font-size: 16.5px;
  }
}
@media (max-width: 380px) {
  .text-18 {
    font-size: 16px;
  }
}

/* ---------- text 20 ---------- */
.text-20 {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  color: #282828;
}
@media (max-width: 1440px) {
  .text-20 {
    font-size: 19.5px;
  }
}
@media (max-width: 1200px) {
  .text-20 {
    font-size: 19px;
  }
}
@media (max-width: 768px) {
  .text-20 {
    font-size: 18.5px;
  }
}
@media (max-width: 380px) {
  .text-20 {
    font-size: 18px;
  }
}

/* ---------- text 22 ---------- */
.text-22 {
  font-size: 22px;
  font-weight: 300;
  line-height: 25px;
  color: #282828;
}
@media (max-width: 1440px) {
  .text-22 {
    font-size: 21.5px;
  }
}
@media (max-width: 1200px) {
  .text-22 {
    font-size: 21px;
  }
}
@media (max-width: 768px) {
  .text-22 {
    font-size: 20.5px;
  }
}
@media (max-width: 380px) {
  .text-22 {
    font-size: 20px;
  }
}

/* ---------- text 24 ---------- */
.text-22 {
  font-size: 24px;
  font-weight: 300;
  line-height: 25px;
  color: #282828;
}
@media (max-width: 1440px) {
  .text-22 {
    font-size: 23.5px;
  }
}
@media (max-width: 1200px) {
  .text-22 {
    font-size: 23px;
  }
}
@media (max-width: 768px) {
  .text-22 {
    font-size: 22.5px;
  }
}
@media (max-width: 380px) {
  .text-22 {
    font-size: 22px;
  }
}
