.blogWrapper {
}
.blogContainer {
  padding: 25px 50px 50px 50px;
}

/* -------- header ------- */
.header {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.header .icon {
  font-size: 17px;
  color: #5b5b5b;
  padding: 0 5px;
}
.blogText {
  color: black;
  cursor: pointer;
  transition: all 0.3s;
}
.blogText:hover {
  color: var(--color-red);
}
.blogTitle {
  color: #494949;
  text-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* --------- blog details */
.blogDetails {
  border-top: 1px solid #e2e2e2;
  padding-top: 20px;
  min-height: 40vh;
}
.blogDate {
  color: #464646;
  padding-bottom: 15px;
}

/* --------------------- media quries------------------ */
/* ---------------------------------------------------- */
@media (max-width: 768px) {
  .blogContainer {
    padding: 25px 20px 50px 20px;
  }
}
