:root {
  --title-color: #414141;
}

/* ---------- title 30 ---------- */
.title-30 {
  font-size: 30px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .title-30 {
    font-size: 29px;
  }
}
@media (max-width: 1200px) {
  .title-30 {
    font-size: 28px;
  }
}
@media (max-width: 768px) {
  .title-30 {
    font-size: 27px;
  }
}
@media (max-width: 460px) {
  .title-30 {
    font-size: 26px;
  }
}
@media (max-width: 380px) {
  .title-30 {
    font-size: 25px;
  }
}

/* ---------- title 34 ---------- */
.title-34 {
  font-size: 34px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .title-34 {
    font-size: 32px;
  }
}
@media (max-width: 1200px) {
  .title-34 {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .title-34 {
    font-size: 28px;
  }
}
@media (max-width: 460px) {
  .title-34 {
    font-size: 26px;
  }
}
@media (max-width: 380px) {
  .title-34 {
    font-size: 24px;
  }
}

/* ---------- title 44 ---------- */
.title-44 {
  font-size: 44px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .title-44 {
    font-size: 38px;
  }
}
@media (max-width: 1200px) {
  .title-44 {
    font-size: 36px;
  }
}
@media (max-width: 768px) {
  .title-44 {
    font-size: 34px;
  }
}
@media (max-width: 460px) {
  .title-44 {
    font-size: 32px;
  }
}
@media (max-width: 380px) {
  .title-44 {
    font-size: 30px;
  }
}

/* ---------- title 46 ---------- */
.title-46 {
  font-size: 46px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .title-46 {
    font-size: 44px;
  }
}
@media (max-width: 1200px) {
  .title-46 {
    font-size: 42px;
  }
}
@media (max-width: 768px) {
  .title-46 {
    font-size: 40px;
  }
}
@media (max-width: 460px) {
  .title-46 {
    font-size: 38px;
  }
}
@media (max-width: 380px) {
  .title-46 {
    font-size: 36px;
  }
}
