.loaderWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100000000;
}
.loaderWrapper img {
  width: 110px;
}
.loaderWrapper p {
  color: #000000;
  font-weight: 400;
  padding-top: 10px;
  font-size: 16px;
  letter-spacing: 1px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 350px;
  height: 350px;
  position: absolute;
  border-radius: 300px;
  border: 2px solid black;
  border-right: none;
  border-bottom: none;
  animation: logo-circle 2s infinite linear;
}

@keyframes logo-circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ------------------------------------ media queries -------------------------------
------------------------------------------------------------------------------------- */
@media (max-width: 768px) {
  .loaderWrapper img {
    width: 100px;
  }
  .circle {
    width: 310px;
    height: 310px;
  }
}
