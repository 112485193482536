.pdfWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pdfContainer {
  width: 900px;
}
.pdfContainer :global(.rpv-core__inner-page) {
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .pdfContainer {
    width: 100%;
  }
}
